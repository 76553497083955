import { useState, useEffect } from "react"
import clsx from "clsx"
import { BsExclamationCircle } from "react-icons/bs"
import { IonInput } from "@ionic/react"
import { IoLinkOutline } from "react-icons/io5"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"
import { GridFormField, GridFormFieldProps } from "../../../Forms/GridFormField"
import isNil from "lodash/isNil"
import VideoPlayer from "../../../Mico/VideoPlayer"

export type MovementFollowAlongCustomUrlInputFormFieldProps = Omit<
  GridFormFieldProps<string>,
  "Body" | "modalName" | "label"
>

export interface MovementFollowAlongCustomUrlInputProps {
  initialValue?: string
  onChange: (value: string) => void
  className?: string
}

export const CustomURLInputGridFormField = ({
  name,
  className,
  ...props
}: MovementFollowAlongCustomUrlInputFormFieldProps) => {
  // Format the displayed value for the grid
  const formatValue = (value?: string) => {
    return value || "Enter URL" // Default display text
  }

  // This would return the GridFormField with a modal that contains your custom URL input
  return (
    <GridFormField<string>
      name={name}
      modalName={ModalOrchestrationName.FormFieldCustomURLInput}
      label="URL"
      title="YouTube URL"
      Body={CustomURLInput}
      Icon={IoLinkOutline}
      formatValue={formatValue}
      className={className}
      showSubmitButton
      showClearButton
      {...props} // Spread any other props you might need to pass
    />
  )
}

export const CustomURLInput = ({
  state,
  setState,
  className,
}: {
  state?: string
  setState: (value: string) => void
  className?: string
}) => {
  const [url, setUrl] = useState(state || "")
  const [isValid, setIsValid] = useState(true)

  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/

  const validateUrl = (inputUrl: string) => {
    return youtubeRegex.test(inputUrl)
  }

  useEffect(() => {
    if (isValid && url !== state) {
      setState(url)
    }
  }, [url, isValid])

  useEffect(() => {
    if (url === "" || url === state) return

    setIsValid(validateUrl(url))
  }, [url])

  useEffect(() => {
    if (isNil(state)) return

    setUrl(state)
  }, [state])

  const handleChange = (event: any) => {
    const inputUrl = event.target.value

    if (validateUrl(inputUrl)) {
      setIsValid(true)
      setUrl(inputUrl)
    } else {
      setIsValid(false)
      setState("")
    }
  }

  const inputClasses = clsx(
    "z-0 w-full p-2 px-10 font-semibold bg-white text-neutral-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500",
    {
      "ring-1 ring-red-300 text-red-900 placeholder:text-red-300": !isValid,
    },
    className // This allows custom styles to be applied
  )

  return (
    <div className="relative rounded-md w-full px-8 h-full flex-col items-start gap-6">
      <div className="relative mt-2 rounded-md shadow-sm">
        <IonInput
          type="text"
          name="youtube-url"
          id="youtube-url"
          className={inputClasses}
          placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          onIonChange={handleChange}
          value={url}
          aria-invalid={!isValid}
          aria-describedby={!isValid ? "youtube-url-error" : undefined}
          style={{
            "--padding-start": "0.5rem",
            "--padding-end": "2.25rem",
          }}
        />

        {!isValid && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <BsExclamationCircle
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {!isValid && (
        <p className="mt-2 text-sm text-red-600" id="youtube-url-error">
          Please enter a valid YouTube URL.
        </p>
      )}

      {!isNil(url) && isValid && (
        <div className="flex flex-col items-start justify-center gap-2 py-6">
          <span className="text-sm font-semibold text-neutral-600">
            Preview
          </span>
          <VideoPlayer src={url} />
        </div>
      )}
    </div>
  )
}
